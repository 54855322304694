<template>
  <div class="flex-column">
    <div class="m-auto shadow mt-5 p-5" style="font-size: 1.4em">
      <h1 style="text-align: center">Welcome To iAppraise</h1>
      <p class="vertical-spacing-top-half">
        We are currently undergoing some maintenance and will be back online shortly!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Maintenance"
};
</script>

<style scoped></style>
